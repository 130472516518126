import React from 'react'
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import Language from "./Language"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const PageNav = (props) => {

    return (
        <div className="sir-section grid grid-cols-1 sm:grid-cols-2 gap-4 bg-sir-black text-white">
            <div>
                <h3 className="body-s uppercase mb-4">
                <FormattedMessage id="global.menu.greatlove" />
                <span className="hidden md:inline-block mx-1">/</span>
                <span className="visible md:hidden"><br /></span>
                <FormattedMessage id="global.menu.beautifulrotting" /></h3>
                <ul>
                    <li className="title uppercase mb-2"><Link to="/great-love"><FormattedMessage id="global.menu.artwork" /></Link></li>
                    <li className="title uppercase mb-2"><Link to="/great-love/texts"><FormattedMessage id="global.menu.texts" /></Link></li>
                    <li className="title uppercase mb-2"><Link to="/great-love/team"><FormattedMessage id="global.menu.team" /></Link></li>
                    <li className="title uppercase mb-2"><Link to="/great-love/supporters"><FormattedMessage id="global.menu.supporters" /></Link></li>
                </ul>
            </div>

            <div>
                <h3 className="body-s uppercase mb-4">
                <FormattedMessage id="global.menu.sirje" /><span> </span>
                <span className="visible md:hidden"><br /></span>
                <FormattedMessage id="global.menu.runge" /></h3>
                <ul>
                    <li className="title uppercase mb-2"><Link to="/works"><FormattedMessage id="global.menu.works" /></Link></li>
                    <li className="title uppercase mb-2"><Link to="/biography"><FormattedMessage id="global.menu.biography" /></Link></li>
                    <li className="title uppercase mb-2"><Link to="/contact"><FormattedMessage id="global.menu.contact" /></Link></li>
                </ul>

            </div>

            <div><Language /></div>
        </div>

    )
}

export default PageNav;