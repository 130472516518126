import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/SEO"
import Header from "../../components/Header"
import PageNav from "../../components/PageNav"

export default function GeometryXI() {
    const intl = useIntl()
    return (
        <div>
            <Seo title={intl.formatMessage({ id: "works.work8.title.seo" })} />
            <Header />

            <div className="sir-breadcrumbs">
                <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <Link class="text-sir-black-50" to="/works"><FormattedMessage id="global.menu.works" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <FormattedMessage id="works.work8.title" />
            </div>

            <div className="sir-section">
                <div className="sir-layout-work">
                    <StaticImage className="w-full" src="../../images/works/08.png" alt={intl.formatMessage({ id: "works.work8.title" })} />
                </div>
                <div className="text-center body-s uppercase mt-16 mb-16">
                    <span className="mr-4"><FormattedMessage id="works.work8.title" /></span>
                    <span className="opacity-50 mr-4"><FormattedMessage id="works.work8.year" /></span>
                    <span className="opacity-50 mr-4"><FormattedMessage id="works.work8.medium" /></span>
                    <span className="opacity-50 mr-4"><FormattedMessage id="works.work8.dimensions" /></span>
                    <span className="opacity-50"><FormattedMessage id="works.work8.location" /></span>
                </div>
            </div>

            <PageNav />
        </div>
    )
}
